import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";
import axios from "axios";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

export const postLastSession = async ({
  email = null,
  latestTenant = null,
  latestComputerId = null,
  latestUserId = null,
  resourceId = null,
  latestPublisherId = null,
  latestAppId = null,
  latestFolderId = null,
  latestGroupId = null,
  latestTenantMode = null,
  latestUsername = null,
}) => {
  const response = await axios.post(`${NGROK}/api/latest-checking`, {
    email,
    latestTenant,
    latestComputerId,
    latestUserId,
    resourceId,
    latestPublisherId,
    latestAppId,
    latestFolderId,
    latestGroupId,
    latestTenantMode,
    latestUsername,
  });
  myLocalStorage.setItem(`${email}-lastSession`, response.data);
  return response.data;
};

export const getLastSession = async (userEmail) => {
  const lastSession = myLocalStorage.getItem(`${userEmail}-lastSession`);
  if (lastSession && Object.keys(lastSession)?.length) return lastSession;
  else {
    try {
      const response = await axios.get(`${NGROK}/api/latest-checking`);
      myLocalStorage.setItem(`${userEmail}-lastSession`, response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
};

export const initialCategories = {
  appCategories: [
    { name: "Malware" },
    { name: "Pre-existing" },
    { name: "New" },
  ],
  folderCategories: [{ name: "Pre-existing" }, { name: "New" }],
  privilegeCategories: [
    { name: "Pre-existing" },
    { name: "New" },
    { name: "Sensitive" },
  ],
  publisherCategories: [
    { name: "Pre-existing" },
    { name: "New" },
    { name: "OS provider" },
    { name: "Well known" },
    { name: "Banned" },
  ],
};

export const sortOrderLinuxProfiles = [
  "linux_critical",
  "linux_secure",
  "linux_normal",
  "linux_original",
];
export const sortOrderWindowsProfiles = [
  "windows_critical",
  "windows_secure",
  "windows_normal",
  "windows_original",
];

export const sortOrderMacProfiles = [
  "mac_critical",
  "mac_secure",
  "mac_normal",
  "mac_original",
];

export const resourceTypeListForCategoriesPage = [
  {
    name: "App",
    id: 1,
    icons: <AppsOutlinedIcon />,
  },
  {
    name: "Folder",
    id: 2,
    icons: <FolderOutlinedIcon />,
  },
  // {
  //   name: "Group",
  //   id: 3,
  // },
  // {
  //   name: "Publisher",
  //   id: 4,
  // },
  {
    name: "Url",
    id: 5,
    icons: <LinkOutlinedIcon />,
  },
];

export const generalProfileOrder = ["critical", "secure", "normal", "original"];
export const sortProfiles = (profiles) => {
  return profiles.sort((a, b) => {
    return a.name - b.name;
  });
};

export const getResourceNameField = (resourceType) => {
  const resourceNameField =
    resourceType === "App" || resourceType === "Folder"
      ? "path"
      : resourceType === "Group"
        ? "cn"
        : resourceType === "Publisher"
          ? "friendlyName"
          : "name";
  return resourceNameField;
};

export const getProfileResourceNameField = (resourceType) => {
  const resourceNameField =
    resourceType === "App"
      ? "appPath"
      : resourceType === "Folder"
        ? "path"
        : resourceType === "Group"
          ? "cn"
          : resourceType === "Publisher"
            ? "friendlyName"
            : "urlName";
  return resourceNameField;
};

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getFilteredEndpointsResources = (
  searchValue,
  selectedResoruceValue,
  resources,
  isFromApp = false,
) => {
  const getResourceName = (resource) => {
    if (resource.hasOwnProperty("folderId")) return "path";
    else if (resource.hasOwnProperty("appId")) return "appName";
    else if (resource.hasOwnProperty("urlId")) return "name";
  };

  let resourceReturn = resources?.length
    ? resources
        .filter((resource) => {
          if (isFromApp) {
            resource = resource?.computerUsersPublishersAppsMembership;
          }
          if (searchValue === "") {
            return resource;
          } else {
            if (resource?.hasOwnProperty("adComputerUserGroup")) {
              if (
                resource?.adComputerUserGroup.cn
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
                return resource;
            } else if (
              resource[getResourceName(resource)]
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
              return resource;
            }
          }
          return null;
        })
        .filter((resource) => {
          if (isFromApp) {
            resource = resource?.computerUsersPublishersAppsMembership;
          }
          if (selectedResoruceValue === "ALL") return resource;
          else {
            const trustLevel = {
              ALLOWED: "ENABLED",
              DENIED: "DISABLED",
              TIMED: "DYNAMIC",
            };
            const inheritedTrustLevel = {
              ALLOWED: "ENABLED",
              DENIED: "DISABLED",
              TIMED: "DYNAMIC",
            };

            return resource?.groupStatus !== "INHERITED"
              ? resource?.groupStatus === trustLevel[selectedResoruceValue]
              : resource?.inheritedGroupStatus ===
                  inheritedTrustLevel[selectedResoruceValue];
          }
        })
    : [];

  return resourceReturn;
};

export const getFilteredProfileResources = (
  searchValue,
  selectedResoruceValue,
  resources,
  isFromApp = false,
) => {
  const getResourceName = (resource) => {
    if (resource.hasOwnProperty("folderId")) return "path";
    else if (resource.hasOwnProperty("appId")) return "appName";
    else if (resource.hasOwnProperty("urlName")) return "urlName";
    else if (resource.hasOwnProperty("groupId")) return "cn";
  };
  return resources?.length
    ? resources
        // eslint-disable-next-line array-callback-return
        .filter((resource) => {
          if (isFromApp) {
            resource = resource?.defaultProfileAppsResource;
          }
          if (searchValue === "") {
            return resource;
          } else if (
            resource[getResourceName(resource)]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            return resource;
          } else return null;
        })
        .filter((resource) => {
          if (isFromApp) {
            resource = resource?.defaultProfileAppsResource;
          }
          if (selectedResoruceValue === "ALL") return resource;
          else {
            const trustLevel = {
              ALLOWED: "ALLOWED",
              DENIED: "BLOCKED",
              TIMED: "DYNAMIC",
            };
            const inheritedTrustLevel = {
              ALLOWED: "ENABLED",
              DENIED: "DISABLED",
              TIMED: "DYNAMIC",
            };
            return resource.profileResource !== "INHERITED"
              ? resource.profileResource === trustLevel[selectedResoruceValue]
              : resource.trustLevel ===
                  inheritedTrustLevel[selectedResoruceValue];
          }
        })
    : [];
};

export const checkIfUserHasAccess = (
  resource,
  userData,
  timedResouresWithoutAccess,
  resourceId,
) => {
  if (userData.role !== "TENANT_USER") return true;

  const statusBoolean = {
    DISABLED: false,
    ENABLED: true,
    DYNAMIC: true,
  };

  if (
    resource.groupStatus === "DYNAMIC" &&
    timedResouresWithoutAccess.includes(resourceId)
  ) {
    return resource.remainingTime > 0 ? true : false;
  } else if (resource.groupStatus !== "INHERITED") {
    return timedResouresWithoutAccess.includes(resourceId)
      ? false
      : statusBoolean[resource.groupStatus];
  } else {
    return timedResouresWithoutAccess.includes(resourceId)
      ? false
      : statusBoolean[resource.inheritedGroupStatus];
  }
};

export const userTrustLevelStyles = (resource) => {
  return resource.groupStatus === "ENABLED" ||
    resource.inheritedGroupStatus === "ENABLED"
    ? "privilegeLevelTableCell__centerItem"
    : "privilegeLevelTableCell__dynamicItem";
};

export const adminTrustLevelStyles = (resource) =>
  ["ENABLED", "DISABLED"].includes(resource.groupStatus) ||
  ["ENABLED", "DISABLED"].includes(resource.inheritedGroupStatus)
    ? "privilegeLevelTableCell__centerItem"
    : "privilegeLevelTableCell__dynamicItem";

export const getInheritedResourceStatusValue = (resource) => {
  if (resource.profileResource === "INHERITED") {
    const trustLevel = {
      ENABLED: "ALLOWED",
      DISABLED: "DENIED",
      DYNAMIC: "TIMED",
    };
    return `(${trustLevel[resource.trustLevel]})`;
  }
};

export const getInheritedEndpointResourceStatusValue = (resource) => {
  if (resource.groupStatus === "INHERITED") {
    const groupStatus = {
      ENABLED: "ALLOWED",
      DISABLED: "DENIED",
      DYNAMIC: "TIMED",
    };
    return `(${groupStatus[resource.inheritedGroupStatus]})` || "";
  }
};

export const getResourceSelectValue = (resource) => {
  const resourceMapping = {
    ALLOWED: "ENABLED",
    BLOCKED: "DISABLED",
    DYNAMIC: "DYNAMIC",
    INHERITED: "INHERITED",
  };

  return resourceMapping[resource.profileResource];
};

export const filterPublishers = (publishers) => {
  if (publishers?.length) {
    const publishersWithoutDuplicates = publishers
      .map((publisher) => ({ ...publisher, ids: [publisher.id] }))
      .reduce((acc, current) => {
        const x = acc.find(
          (publisher) => publisher.friendlyName === current.friendlyName,
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          x.ids.push(current.id);
          return acc;
        }
      }, []);
    return publishersWithoutDuplicates;
  }
};

export const sortObjectsAlphabetically = (array, name, resource) => {
  let returnArray = [];

  if (array && name && resource) {
    returnArray = array.sort((a, b) => {
      const aScore = a?.applicationReputation?.score ?? null;
      const bScore = b?.applicationReputation?.score ?? null;

      if (aScore !== null && bScore !== null) {
        if (aScore !== bScore) return bScore - aScore;
      } else if (aScore === null) {
        return 1;
      } else if (bScore === null) {
        return -1;
      }

      const aName = a[resource][name]?.toLowerCase() || "";
      const bName = b[resource][name]?.toLowerCase() || "";
      return aName.localeCompare(bName);
    });
  }

  return returnArray;
};

export const clearLocalStorageOnLogout = () => {
  // myLocalStorage.removeItem("access_token");
  // myLocalStorage.removeItem("refresh_token");
  // myLocalStorage.removeItem("realm");
  // myLocalStorage.removeItem("email");
  // myLocalStorage.removeItem("sessionTime");
  // myLocalStorage.removeItem("role");
  // myLocalStorage.removeItem("logoutUri");
  localStorage.clear();
};

export const setInitialSessionTime = (time = 30) => {
  const sessionTime = myLocalStorage.getItem("sessionTime");

  if (!sessionTime)
    myLocalStorage.setItem("sessionTime", { min: time, sec: 0 });
};

export const createCategoryPatternRegex = (
  pattern,
  caseInsensitive = false,
) => {
  const escapedPattern = pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  const flags = caseInsensitive ? "i" : "";
  return new RegExp(escapedPattern, flags);
};

export const createDeviceGroupPatternRegex = (pattern) => {
  const escapedPattern = pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  return new RegExp(escapedPattern, "i");
};

export const toLowerCaseExceptFirst = (str) =>
  `${str[0]}${str.slice(1).toLowerCase()}`;

export const stringifyUrlParam = (data) => {
  return Object.entries(data)
    .map((e) => e.join("="))
    .join("&");
};

export const checkIfUserHassAccess = (group) => {
  if (!group) return false;

  const hassAccess = {
    ENABLED: true,
    DISABLED: false,
    DYNAMIC: group.remainingTime ? true : false,
  };

  const selectedGroupStatus =
    group.groupStatus !== "INHERITED"
      ? group.groupStatus
      : group.inheritedGroupStatus;

  return hassAccess[selectedGroupStatus];
};

export const setLocalStorageTenant = (tenant) => {
  myLocalStorage.setItem("latestTenant", tenant);
};

export const containsUpperCase = (str) => /[A-Z]/.test(str);

export const usersRoutes = [
  "/endpoints",
  "/endpoints/resources",
  "/userResourceRequests",
  "/account",
  "/downloads",
  "/about",
];
